import { Box, Grid } from '@mui/material'
import React from 'react'
import Header from './components/Header/Header'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Portfolio from './pages/portfolio/Portfolio'
import PortfolioDetails from './pages/portfolioDetails/PortfolioDetails'
import ServicePage from './pages/service/ServicePage'
import Contact from './pages/contact/Contact'
import Career from './pages/career/Career'
import Footer from './components/Footer/Footer'
import ResponsiveFooter from './components/ResponsiveFooter/ResponsiveFooter'
import ResponsiveHeader from './components/ResponsiveHeader/ResponsiveHeader'
import Team from './pages/team/Team'
import styles from './App.module.scss'

const App = () => {

  return (
    <Grid className={styles.appContainer}> 
      <Box>
        {/* {window.innerWidth > 480 ? <Header /> : <ResponsiveHeader />} */}
        <Header />
        <ResponsiveHeader />
      </Box>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/service' element={<ServicePage />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/portfolio/:id' element={<PortfolioDetails />} />
        {/* <Route path='/team' element={<Team />} /> */}
        <Route path='/career' element={<Career />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>

      {window.innerWidth > 480 ? <Footer /> : <ResponsiveFooter />}
    </Grid>
  )
}

export default App