import img from '../../asserst/img/service/Image (6).png'
import img1 from '../../asserst/img/service/Image (5).png'
import img2 from '../../asserst/img/service/Image (1).png'
import img3 from '../../asserst/img/service/Image (2).png'
import img4 from '../../asserst/img/service/Image (3).png'
import img5 from '../../asserst/img/service/Image (4).png'
import idea from '../../asserst/img/service/idea/Image.png'
import idea1 from '../../asserst/img/service/idea/Image (1).png'
import idea2 from '../../asserst/img/service/idea/Image (2).png'
import idea3 from '../../asserst/img/service/idea/Image (3).png'
import idea4 from '../../asserst/img/service/idea/Image (4).png'
import idea5 from '../../asserst/img/service/idea/Image (5).png'
import idea6 from '../../asserst/img/service/idea/Image (6).png'
import res_1 from '../../asserst/icons/bigideas/Untitled design-4.png'
import res_2 from '../../asserst/icons/bigideas/Untitled design-5.png'
import res_3 from '../../asserst/icons/bigideas/Untitled design-6.png'
import res_4 from '../../asserst/icons/bigideas/Untitled design-7.png'
import res_5 from '../../asserst/icons/bigideas/Untitled design-8.png'
import res_6 from '../../asserst/icons/bigideas/expertise.png'
import Software from '../../asserst/img/service/software_dev.png'
import ui from '../../asserst/img/service/ui.png'
import maintenace from '../../asserst/img/service/maitance.png'
import team from '../../asserst/img/service/Staffing Services.png'
import editing from '../../asserst/img/service/Video Editing.png'


export const featureService = [
    {
        "id": 1,
        "img": img1,
        "heading": "Web Development:",
        "desc": "We offer comprehensive web development services to help businesses establish a strong online presence and achieve their digital goals. Our expertise includes front-end and back-end development, e-commerce solutions, content management systems (CMS), and web application development. We focus on creating responsive, user-friendly websites that are optimized for performance and accessibility across all devices and platforms."
    },
    {
        "id": 2,
        "img": img,
        "heading": "Mobile App Development:",
        "desc": "Our mobile app development services cover the design, development, and deployment of native and cross-platform mobile applications for iOS and Android devices. We leverage cutting-edge technologies and industry best practices to create intuitive, feature-rich mobile apps that enhance user engagement and drive business growth. Whether you need a consumer-facing app or an enterprise-grade solution, we have the expertise to bring your ideas to life."
    },
    {
        "id": 3,
        "img": Software,
        "heading": "Software Development:",
        "desc": " Developing custom software solutions to automate workflows, improve efficiency, and meet unique business requirements. From CRM systems to enterprise resource planning (ERP) solutions, we deliver robust software tailored to your specifications.Digital Marketing: Implementing strategic digital marketing campaigns to increase brand visibility, attract targeted traffic, and drive conversions. Our services include search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, and content marketing."
    },
    {
        "id": 4,
        "img": ui,
        "heading": "UI/UX Design: ",
        "desc": "Our UI/UX services prioritize user-centric design principles to create intuitive and visually appealing digital experiences. From wireframing and prototyping to user research and testing, we craft seamless interfaces that enhance user engagement and satisfaction. Our expert team leverages industry best practices and cutting-edge design tools to deliver solutions tailored to your brand identity and target audience. Whether it's a mobile app, website, or software application, we focus on usability, accessibility, and aesthetics to ensure an exceptional user experience that drives conversions and fosters long-term customer loyalty. Partner with us to elevate your digital presence and differentiate your brand in the competitive market."
    },
    {
        "id": 5,
        "img": img4,
        "heading": "Graphic designing:",
        "desc": "Our graphic design services offer creative solutions to visually represent your brand, including logo design, branding materials, and marketing collateral. With a keen eye for aesthetics and attention to detail, we bring your vision to life with captivating designs that resonate with your target audience."
    },
    {
        "id": 6,
        "img": editing,
        "heading": "Video Editing:",
        "desc": "For video editing, we specialize in crafting engaging and professional videos tailored to your needs. From promotional videos and social media content to training videos and event highlights, we skillfully edit footage to tell your story effectively. With expertise in video production techniques and editing software, we deliver high-quality videos th"
    },
    {
        "id": 7,
        "img": team,
        "heading": "Staffing Services: ",
        "desc": "Our IT staffing services provide tailored workforce solutions to meet your organization's specific needs. We specialize in recruiting and placing highly skilled IT professionals across various domains, including software development, cybersecurity, cloud computing, and more. With a rigorous screening process and access to a vast network of qualified candidates, we ensure the right fit for your team. Whether you need contract, contract-to-hire, or direct-hire placements, we're here to support your staffing needs and drive your business forward."
    },
    {
        "id": 8,
        "img": maintenace,
        "heading": "Support and Maintenance: ",
        "desc": "Providing ongoing support and maintenance services to ensure the smooth operation and performance of your digital solutions. From troubleshooting issues to implementing updates and enhancements, our dedicated support team is here to assist you every step of the way."
    },
]

export const whyChoose = [
    {
        "id": 1,
        "icon": res_6,
        "heading": "Expertise",
        "desc": "We offer a team of highly skilled professionals with expertise in various IT domains, ensuring top-notch solutions tailored to your specific needs."
    },
    {
        "id": 2,
        "icon": res_5,
        "heading": "Customized Solutions",
        "desc": "We provide personalized IT solutions designed to address your unique business challenges and goals, ensuring maximum efficiency and effectiveness."
    },
    {
        "id": 3,
        "icon": res_1,
        "heading": "Innovation",
        "desc": "We stay at the forefront of technological advancements, offering innovative solutions that help you stay competitive in your industry."
    },
    {
        "id": 4,
        "icon": res_2,
        "heading": "Client-Centric Approach",
        "desc": "We prioritize client satisfaction, fostering strong relationships and delivering results-driven services that exceed expectations, every time."
    },
    {
        "id": 5,
        "icon":res_4,
        "heading": "Cost-Effective Solutions",
        "desc": "OTUSONE LLP offers competitive pricing without compromising on quality, providing cost-effective solutions for your big ideas."
    },
    {
        "id": 6,
        "icon": res_3,
        "heading": "Continuous Support",
        "desc": "Our commitment doesn't end with project delivery. We provide ongoing support and maintenance to ensure the longevity and success of your big ideas."
    },
]

export const ideaOne = [
    {
        "id": 1,
        "img": idea,
        "heading": "The Standard Journey",
        "desc": `"Experience the Freedom of Mobility with Our Premier Car Rental Service. “`
    },
    {
        "id": 1,
        "img": idea1,
        "heading": "Fresh Menu",
        "desc": `"Savor the Convenience of Ordering Food Online. Indulge in a Culinary Adventure from the Comfort of Your Home.” `
    },
    {
        "id": 1,
        "img": idea2,
        "heading": "Vinsfertility",
        "desc": `"Providing Compassionate Care and Expert Medical Services. Your Health and Well-being are Our Priority.”`
    }
]
export const ideaOne2 = [
    {
        "id": 1,
        "img": idea3,
        "heading": "Manish Packers & Movers",
        "desc": `"Reliable Transport Solutions Tailored to Your Needs. Whether it's Commuting, Delivery, or Logistics, We Ensure Safe and Efficient Journeys,”`
    },
    {
        "id": 1,
        "img": idea4,
        "heading": "Simitri",
        "desc": `"Empowering Your Online Shopping Experience. Explore a World of Possibilities, Seamless Transactions, and Endless Choices.”`
    },
    {
        "id": 1,
        "img": idea5,
        "heading": "Pratham",
        "desc": `"Igniting Minds, Empowering Futures. Our Commitment to Excellence in Education Ensures Every Student Thrives.”`
    }
]