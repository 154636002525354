import React, { useState } from 'react'
import styles from './Career.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import CareerBanner from '../../components/CareerBanner/CareerBanner'
import { about, jobs } from './data'
import HeadingText from '../../components/HeadingText/HeadingText'
import img from '../../asserst/icons/jobs.png'
import JobModal from '../../components/modal/JobModal/JobModal'
import { baseurl } from '../../baseUrl/baseUrl'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import carrer_1 from '../../asserst/img/career_1.png'

export interface ICareer {
    handleClick: () => void;
}
const Career = () => {
    const [open, setOpen] = useState(false);
    const handeClose = () => setOpen(false);
    const [inputVal, setInputVal] = useState({ name: "", email: "", phone: "", qualification: "", experience: "", current_ctc: "", expected_ctc: "", resume: "" })
    const handleModal = (idx: any) => {
        setOpen((preState: any) => ({ ...preState, [idx]: !preState[idx] }))
    };

    const handleChange = (e: any) => {
        const { name, value, type, files } = e.target;
        const val = type === 'file' ? files[0] : value
        setInputVal({ ...inputVal, [name]: val })
    };

    const handleClick = async () => {
        try {
            const formData = new FormData();
            formData.append('name', inputVal.name);
            formData.append('email', inputVal.email);
            formData.append('phone', inputVal.phone);
            formData.append('qualification', inputVal.qualification);
            formData.append('experience', inputVal.experience);
            formData.append('current_ctc', inputVal.current_ctc);
            formData.append('expected_ctc', inputVal.expected_ctc);
            formData.append('resume', inputVal.resume);
            const response = await axios.post(`${baseurl}/job/post`, formData)
            if (response.status === 201) {
                toast.success(response.data.message)
                await setOpen(false)
                setInputVal({ name: "", email: "", phone: "", qualification: "", experience: "", current_ctc: "", expected_ctc: "", resume: "" })
            }
        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <Grid className={styles.careerContainer}>
            <CareerBanner />
            <Grid className={styles.careerAbout}>
                {about.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <Typography variant='h2' fontSize={25} fontWeight={600}>{item.heading}</Typography>
                            <Typography fontSize={14}>{item.subHeading}</Typography>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid className={styles.joinUs}>
                <HeadingText
                    heading={'COME AND JOIN US!'}
                    subHeading={'We at OTUSONE understand the state-of-the-business-art technologies that define the future and can serve as an efficient services transformation catalyst for the clients!'}
                />
                <Grid container className={styles.jobsCard}>
                    {jobs.map((item) => {
                        return (
                            <Grid item sm={4} className={styles.card} onClick={() => handleModal(item.id)}>
                                <img src={item.icon} width={65} height={65} />
                                <Box>
                                    <Typography variant='h2' fontSize={17} fontWeight={600}>{item.label}</Typography>
                                    <Typography fontSize={13}>{item.desc}</Typography>
                                </Box>
                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>
            <JobModal
                open={open}
                inputVal={inputVal}
                handleChange={handleChange}
                handeClose={handeClose}
                handleClick={handleClick}
            />
            <ToastContainer />
        </Grid>
    )
}

export default Career