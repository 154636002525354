import icon1 from '../../asserst/icons/serviceIcon/Group 38592.svg'
import icon2 from '../../asserst/icons/serviceIcon/Group 38593.svg'
import icon3 from '../../asserst/icons/serviceIcon/Group 38594.svg'
import icon4 from '../../asserst/icons/serviceIcon/Group 38595.svg'
import img from '../../asserst/img/service/1.png'
import img2 from '../../asserst/img/service/2.png'
import img3 from '../../asserst/img/service/3.png'
import img4 from '../../asserst/img/service/4.png'
import img5 from '../../asserst/img/service/5.png'
import img6 from '../../asserst/img/service/6.png'
import webIcon_1 from '../../asserst/icons/serviceIcon/web/Group 38595.png'
import webIcon_2 from '../../asserst/icons/serviceIcon/web/Group 38615.png'
import webIcon_3 from '../../asserst/icons/serviceIcon/web/Group 38616.png'
import webIcon_4 from '../../asserst/icons/serviceIcon/web/Group 38617.png'
import appIcon_1 from '../../asserst/icons/serviceIcon/app/Group 38614.png'
import appIcon_2 from '../../asserst/icons/serviceIcon/app/Group 38594.png'
import appIcon_3 from '../../asserst/icons/serviceIcon/app/Group 38593.png'
import appIcon_4 from '../../asserst/icons/serviceIcon/app/Group 38592.png'
import softwareIcon_1 from '../../asserst/icons/serviceIcon/software/Group 38602.png'
import softwareIcon_3 from '../../asserst/icons/serviceIcon/software/Group 38611.png'
import softwareIcon_2 from '../../asserst/icons/serviceIcon/software/Group 38612.png'
import softwareIcon_4 from '../../asserst/icons/serviceIcon/software/Group 38613.png'
import graphic_1 from '../../asserst/icons/serviceIcon/graphics/Group 38606.png'
import graphic_3 from '../../asserst/icons/serviceIcon/graphics/Group 38609.png'
import graphic_2 from '../../asserst/icons/serviceIcon/graphics/Group 38626.png'
import graphic_4 from '../../asserst/icons/serviceIcon/graphics/Group 38633.png'
import video_1 from '../../asserst/icons/serviceIcon/video/Group 38627.png'
import video_3 from '../../asserst/icons/serviceIcon/video/Group 38628.png'
import video_2 from '../../asserst/icons/serviceIcon/video/Group 38629.png'
import video_4 from '../../asserst/icons/serviceIcon/video/Group 38630.png'

export const expertise = [
    {
        "id": 1,
        "label": "Web Development",
        "icons": [
            {
                "id": 1,
                "icon": webIcon_1
            },
            {
                "id": 1,
                "icon": webIcon_4
            },
            {
                "id": 1,
                "icon": webIcon_3
            },
            {
                "id": 1,
                "icon": webIcon_2
            }

        ],
        "img": img2,
        "desc": "We excel in web development, crafting dynamic and user-friendly websites tailored to your needs. Our team specializes in creating responsive designs, optimizing performance, and ensuring seamless functionality. Trust us to bring your online presence to life with our expertise in web development"
    },
    {
        "id": 2,
        "label": "Mobile App Development",
        "icons": [
            {
                "id": 1,
                "icon": appIcon_1
            },
            {
                "id": 1,
                "icon": appIcon_3
            },
            {
                "id": 1,
                "icon": appIcon_2
            },
            {
                "id": 1,
                "icon": appIcon_4
            }

        ],
        "img": img,
        "desc": "We specialize in mobile app development, creating innovative solutions for iOS and Android platforms. Our expert team handles every aspect, from conceptualization to execution, ensuring high-quality, user-friendly applications that meet your needs and exceed expectations. Trust us to bring your app ideas to life with precision and professionalism."
    },
    {
        "id": 3,
        "label": "Software Development",
        "icons": [
            {
                "id": 1,
                "icon": softwareIcon_1
            },
            {
                "id": 1,
                "icon": softwareIcon_4
            },
            {
                "id": 1,
                "icon": softwareIcon_2
            },
            {
                "id": 1,
                "icon": softwareIcon_3
            }

        ],
        "img": img3,
        "desc": "We specialize in IT software development, offering comprehensive services from concept to execution. Our expertise includes application development, testing, and ongoing support to ensure optimal performance and reliability."
    },
    {
        "id": 4,
        "label": "UI/UX Design",
        "icons": [
            {
                "id": 1,
                "icon": graphic_1
            },
            {
                "id": 1,
                "icon": graphic_4
            },
            {
                "id": 1,
                "icon": graphic_3
            },
            {
                "id": 1,
                "icon": graphic_2
            }

        ],
        "img": img6,
        "desc": "Our UI/UX design team creates intuitive and visually appealing interfaces that prioritize user experience. Through meticulous research and prototyping, we craft seamless digital experiences that engage users and drive business objectives. Trust us to design interfaces that captivate and delight users, ensuring optimal engagement and satisfaction."
    },
    {
        "id": 5,
        "label": "Graphic Designing ",
        "icons": [
            {
                "id": 1,
                "icon": graphic_1
            },
            {
                "id": 1,
                "icon": graphic_4
            },
            {
                "id": 1,
                "icon": graphic_3
            },
            {
                "id": 1,
                "icon": graphic_2
            }
        ],
        "img": img4,
        "desc": "Graphic Designing creates visual concepts to communicate ideas that inspire, inform, and captivate audiences. Utilizing typography, photography, and illustration, designers craft compelling visuals for branding, marketing, and communication purposes. "
    },
    {
        "id": 6,
        "label": "Video Editing",
        "icons": [
            {
                "id": 1,
                "icon": video_1
            },
            {
                "id": 1,
                "icon": video_2
            },
            {
                "id": 1,
                "icon": video_3
            },
            {
                "id": 1,
                "icon": video_4
            }

        ],
        "img": img5,
        "desc": "We specialize in custom software development, tailoring solutions to meet your unique needs. Our experienced team ensures efficient development, rigorous testing, and ongoing support for seamless implementation and growth."
    }
]
export const appService = [
    {
        "id": 1,
        "icon": icon1,
        "label": "Hybrid app"
    },
    {
        "id": 2,
        "icon": icon2,
        "label": "Android app"
    },
    {
        "id": 3,
        "icon": icon3,
        "label": "ios"
    },
    {
        "id": 4,
        "icon": icon4,
        "label": "React native"
    }
]
