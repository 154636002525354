import carrer_1 from '../../asserst/img/career_1.png'
import frontend from '../../asserst/img/career/frontend.png'
import backend from '../../asserst/img/career/backend.png'
import fluter from '../../asserst/img/career/fluter.png'
import mern from '../../asserst/img/career/mern.png'
import graphic from '../../asserst/img/career/graphic.png'
import website from '../../asserst/img/career/website.png'
import java from '../../asserst/img/career/java.png'
import ui from '../../asserst/img/career/ui.png'


export const about = [
    {
        "id": 1,
        "heading": "200+",
        "subHeading": "Jobs"
    },
    {
        "id": 2,
        "heading": "92%",
        "subHeading": "Satisfaction rate comes from our awesome customers."
    },
    {
        "id": 3,
        "heading": "4.9/5.0",
        "subHeading": "Average customer ratings we have got all over internet."
    }
]
export const jobs = [
    {
        "id": 1,
        "icon": carrer_1,
        "label": "Business Development Manager",
        "desc": "Create customizable illustrations with attractive designs that are made visually through high creativity"
    },
    {
        "id": 2,
        "icon": frontend,
        "label": "Frontend Developer",
        "desc": "Create customizable illustrations with attractive designs that are made visually through high creativity"
    },
    {
        "id": 3,
        "icon": backend,
        "label": "Backend Developer ",
        "desc": "Create customizable illustrations with attractive designs that are made visually through high creativity"
    },
    {
        "id": 4,
        "icon": fluter,
        "label": "Flutter Developer",
        "desc": "Change the appearance of a design into code that will be made into an amazing website"
    },
    {
        "id": 5,
        "icon": mern,
        "label": "MERN Developer",
        "desc": "Create 2d / 3d video animation in a short period of time designed to promote a company product"
    },
    {
        "id": 7,
        "icon": graphic,
        "label": "Graphic Designer",
        "desc": "Change the appearance of a design into code that will be made into an amazing website"
    },
    {
        "id": 8,
        "icon": website,
        "label": "Website Developer",
        "desc": "Create 2d / 3d video animation in a short period of time designed to promote a company product"
    },
    {
        "id": 11,
        "icon": java,
        "label": "Java Developer",
        "desc": "Create 2d / 3d video animation in a short period of time designed to promote a company product"
    },
    {
        "id": 11,
        "icon": ui,
        "label": "UI/UX Designer",
        "desc": "Create 2d / 3d video animation in a short period of time designed to promote a company product"
    }

]