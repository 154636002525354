
import img from '../../asserst/icons/Icon.svg'
import img1 from '../../asserst/icons/growth.svg'
import img2 from '../../asserst/icons/check.svg'
import img3 from '../../asserst/icons//support.svg'
import img4 from '../../asserst/icons/delivery.svg'
import img5 from '../../asserst/icons/localized.svg'

export const data = {
    "keyCardOne": [
        {
            "icon": img,
            "heading": "Competitive Price",
            "content": "Affordable pricing Offering Competitive Prices to Suit Your Budget"
        },
        {
            "icon": img1,
            "heading": "Strategic growth ",
            "content": "Growth-oriented Strategize for Growth with Our Comprehensive Approach."
        },
        {
            "icon": img2,
            "heading": "Quality Product",
            "content": "Unquestionable quality Delivering Quality Products to Exceed Your Expectations."
        }
    ],
    "keyCardTwo": [
        {
            "icon": img3,
            "heading": "24/7 Support ",
            "content": "We're always here to help you with any questions or issues you may have."
        },
        {
            "icon": img4,
            "heading": "Timely dispatch",
            "content": "Expedited shipping We understand the importance of getting your application to market fast."
        },
        {
            "icon": img5,
            "heading": "Localized",
            "content": "We can develop your app to be culturally relevant and accessible to users in different regions"
        }
    ]

}