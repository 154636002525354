import React from 'react'
import styles from './TruestedBy.module.scss'
import { Grid, Typography } from '@mui/material'
import img from '../../asserst/img/client/creditpedia  1.png'
import img2 from '../../asserst/img/client/intgrow 1.png'
import img3 from '../../asserst/img/client/labile  1.png'
import img4 from '../../asserst/img/client/labile -2 1.png'
import img5 from '../../asserst/img/client/vidura  1.png'
import img6 from '../../asserst/img/client/image 49.png'
import img7 from '../../asserst/img/client/image 50.png'
import HeadingText from '../HeadingText/HeadingText'


const TruestedBy = () => {
    const data = [
        {
            "id": 1,
            "img": img3,
        },
        {
            "id": 2,
            "img": img
        },
        {
            "id": 3,
            "img": img4
        },
        {
            "id": 4,
            "img": img2
        },
        {
            "id": 4,
            "img": img5
        },
        {
            "id": 4,
            "img": img7
        },
        {
            "id": 4,
            "img": img6
        }
    ]
    return (
        <Grid className={styles.truestedByContainer}>
            <HeadingText
                heading={'Our Clients'}
                subHeading={'At OTUSONE LLP, our clients are at the heart of everything we do. We prioritize understanding their unique needs, challenges, and goals to deliver tailored solutions that drive tangible results. '}
            />
            <Grid className={styles.imgCard}>
                {data.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <img src={item.img} />
                        </Grid>
                    )
                })}
            </Grid>

        </Grid>
    )
}

export default TruestedBy