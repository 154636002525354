import img from '../../asserst/img/about/Image (1).png'
import img2 from '../../asserst/img/about/Image (2).png'
import img3 from '../../asserst/img/about/Image (3).png'
import icon from '../../asserst/icons/about/Customer review.png'
import icon1 from '../../asserst/icons/about/Fast delivery.png'
import icon2 from '../../asserst/icons/about/Handshake.png'
import icon3 from '../../asserst/icons/about/Idea.png'
import icon4 from '../../asserst/icons/about/Innovation.png'
import icon5 from '../../asserst/icons/about/Target audience.png'
import team1 from '../../asserst/img/about/Rectangle 4698.jpg'
import team2 from '../../asserst/img/about/Rectangle 4698.png'
import team3 from '../../asserst/img/about/Rectangle 4698 (1).jpg'
import team4 from '../../asserst/img/about/Rectangle 4698 (1).png'
import ingrow from '../../asserst/img/client/intgrow 1.png'
import auto from '../../asserst/img/client/labile -2 1.png'
import brainWala from '../../asserst/img/client/image 49.png'



export const card = [
    {
        "id": 1,
        "img": img,
        "heading": "Mission",
        "description": "At OTUSONE LLP, our mission is to deliver unparalleled IT services and solutions tailored to meet the evolving needs of our clients. Through innovation, expertise, and unwavering dedication, we strive to optimize business operations, enhance efficiency, and drive sustainable growth in an ever-changing technological landscape."
    },
    {
        "id": 1,
        "img": img2,
        "heading": "Vision",
        "description": "To be a premier IT service provider, renowned for innovation and excellence. We aspire to empower businesses with cutting-edge technology solutions, driving their success and fostering a digitally transformed world."
    },
    {
        "id": 1,
        "img": img3,
        "heading": "Our Value ",
        "description": "Innovation: We embrace creativity and continually seek new ways to solve challenges and drive progress. Integrity: We uphold the highest ethical standards, fostering trust and transparency in all our interactions."
    }
]
export const whyChoose = [
    {
        "id": 1,
        "img": icon,
        "label": "Expertise",
        "desc": "Our team comprises skilled professionals with extensive experience in IT services."
    },
    {
        "id": 1,
        "img": icon3,
        "label": "Customized Solutions",
        "desc": "We tailor our services to meet the unique needs of each client."
    },
    {
        "id": 3,
        "img": icon2,
        "label": "Reliability",
        "desc": "Count on us for dependable solutions and timely support."
    },
    {
        "id": 4,
        "img": icon4,
        "label": "Innovation",
        "desc": "We stay ahead of industry trends to deliver cutting-edge solutions."
    },
    {
        "id": 5,
        "img": icon5,
        "label": "Customer Focus",
        "desc": "Your satisfaction is our priority; we strive to exceed your expectations."
    },
    {
        "id": 6,
        "img": icon1,
        "label": "Quick delivery",
        "desc": "We understand the importance of getting your app to market fast."
    }
]
export const whyChoose2 = [
    {
        "id": 1,
        "img": icon2,
        "label": "Easy feedback sharing",
        "desc": "With lots of unique blocks, you can easily build a page without coding. Build your next landing page."
    },
    {
        "id": 1,
        "img": icon3,
        "label": "Never miss deadline",
        "desc": "With lots of unique blocks, you can easily build a page without coding. Build your next landing page."
    }
]
export const team = [
    {
        "id": 1,
        "img": team1,
        "name": "Jane Cooper",
        "designation": "Developer"
    },
    {
        "id": 1,
        "img": team2,
        "name": "Brooklyn Simmons",
        "designation": "Designer"
    },
    {
        "id": 1,
        "img": team3,
        "name": "Guy Hawkins",
        "designation": "Marketer"
    },
    {
        "id": 1,
        "img": team4,
        "name": "Kristin Watson",
        "designation": "Photographer"
    }
]
export const customerSays = [
    {
        "id": 1,
        "label": "Intgrow",
        "image": ingrow,
        "mes": [
            {
                "id": 1,
                "message": "Selecting OTUSONE LLP was undoubtedly the smartest move for our project. Their team not only crafted a top-tier product but also provided unparalleled support from start to finish. From conceptualization to post-launch support, OTUSONE's unwavering dedication to our success was evident at every turn."
            }
        ]
    },
    {
        "id": 2,
        "label": "Autotisement ",
        "image": auto,
        "mes": [
            {
                "id": 1,
                "message": "Our journey with OTUSONE LLP has been nothing short of exceptional. Their team didn't just deliver a stellar product – they became invaluable partners every step of the way. From ideation to deployment and beyond, OTUSONE's commitment to our project's success shone through."
            }
        ]
    },
    {
        "id": 3,
        "label": "Brainlearner ",
        "image": brainWala,
        "mes": [
            {
                "id": 1,
                "message": "OTUSONE LLP proved to be the cornerstone of our project's success. Their team didn't just meet our expectations – they exceeded them in every regard. From initial consultation to final delivery, OTUSONE demonstrated unmatched dedication, professionalism, and expertise."
            }
        ]
    }
]