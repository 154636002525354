import React, { useEffect, useState } from 'react'
import styles from './ResponsiveAbout.module.scss'
import { Grid, Typography } from '@mui/material'
import img1 from '../../../asserst/img/team/team_1.png'
import img2 from '../../../asserst/img/team/team_2.png'
import img3 from '../../../asserst/img/team/team_3.png'
import img4 from '../../../asserst/img/team/team_4.png'
import img5 from '../../../asserst/img/team/team_5.png'
import img6 from '../../../asserst/img/team/team_6.png'
import { FaQuoteLeft } from "react-icons/fa";


const ResponsiveAbout = () => {
    const imgSrcList = ["Akanksha Singh", "Aparna Singh", "Ankit Singh", "Aman Shukla", "Narendra Singh", "Abhishek Yadav"];
    const [selectedImg, setSelectedImg] = useState<any>(null);
    const [selectedName, setSelectedName] = useState("Akanksha Singh")
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleImageClick = (imgSrc: any) => {
        setSelectedImg(imgSrc);
        setSelectedName(imgSrc);
    };
    const data = [
        {
            "id": 1,
            "name": "Akanksha Singh",
            "designation": ' Founder & CEO',
            "url": "www.otusone.com",
            "desc": "Akanksha Singh, Founder & CEO of OTUSONE LLP, brings visionary leadership and a passion for innovation to the forefront of our IT company. With a rich background in technology and a commitment to excellence, Akanksha drives our team towards pioneering solutions and client satisfaction. Her strategic foresight and steadfast dedication empower OTUSONE to excel in an ever-evolving digital landscape, consistently delivering cutting-edge services and driving impactful results for our clients. With over 6 years of comprehensive experience in the IT industry, Akanksha's leadership continues to elevate OTUSONE LLP, establishing it as a trusted partner and a beacon of innovation and excellence within the IT community."
        },
        {
            "id": 2,
            "name": "Aparna Singh",
            "designation": 'Co -Founder & HR Head',
            "url": "www.otusone.com",
            "desc": "Aparna Singh, Co-founder & HR Head at OTUSONE LLP. With over 8+ years of experience brings a blend of expertise in human resources and a deep understanding of the IT industry. With a focus on nurturing talent and fostering a vibrant organizational culture, she plays a pivotal role in driving employee engagement and development. Aparna is dedicated to creating an environment where individuals can thrive, ensuring OTUSONE LLP attracts, retains, and empowers top talent to achieve collective success."
        },
        {
            "id": 3,
            "name": "Ankit Singh",
            "designation": 'Director',
            "url": "www.otusone.com",
            "desc": "Ankit Singh, Director at OTUSONE LLP. With over 9+ years of diverse experience spanning electrical engineering, education, IT, and management, Ankit brings a unique blend of expertise to the table. His journey reflects a dynamic career trajectory marked by a relentless pursuit of excellence and innovation across various sectors. Ankit's extensive background enables him to bring a holistic perspective to every project, driving impactful solutions that resonate with clients' needs. As a visionary leader, he spearheads strategic initiatives, guiding OTUSONE LLP towards sustainable growth and success. Ankit's leadership fosters a culture of creativity, collaboration, and continuous improvement, making OTUSONE LLP a frontrunner in the IT industry"
        },
        {
            "id": 4,
            "name": "Aman Shukla",
            "designation": 'BDM & Graphic Designer',
            "url": "www.otusone.com",
            "desc": "Aman Shukla, the dynamic Business Development Manager and talented Graphic Designer at OTUSONE LLP, a premier IT company renowned for innovation. With over 3 years of hands-on experience in the IT sector, Aman brings a unique blend of creativity and strategic acumen to his roles. As a Business Development Manager, he spearheads initiatives to foster strategic partnerships and drive business growth. Simultaneously, Aman's prowess as a Graphic Designer ensures that OTUSONE LLP delivers visually stunning and engaging design solutions to clients. His multidisciplinary expertise and dedication to excellence make him an invaluable asset, driving OTUSONE LLP towards continued success and recognition in the industry"
        },
        {
            "id": 5,
            "name": "Narendra Singh",
            "designation": 'Project Manager & Full Stack Developer',
            "url": "www.otusone.com",
            "desc": "Narendra Singh, the proficient Project Manager and skilled Full Stack Developer at OTUSONE LLP, an esteemed IT firm leading the way in technological innovation. With over 3 years of extensive experience in the IT industry, Narendra brings a wealth of expertise to his dual role. As a Project Manager, he orchestrates the seamless execution of projects, ensuring timely delivery and client satisfaction. Simultaneously, Narendra's proficiency as a Full Stack Developer enables him to architect robust and scalable solutions that meet the diverse needs of clients. His dedication to excellence and leadership qualities play a pivotal role in driving OTUSONE LLP towards continued success and growth in the competitive IT landscape"
        },
        {
            "id": 5,
            "name": "Abhishek Yadav",
            "designation": 'Sr. Mobile Application Developer',
            "url": "www.otusone.com",
            "desc": "Abhishek Yadav, the accomplished Senior Mobile Application Developer at OTUSONE LLP, a leading IT enterprise dedicated to technological excellence. With over 3 years of seasoned experience in the IT domain, Abhishek brings a wealth of expertise and innovation to his role. As a Senior Mobile Application Developer, he specializes in crafting intuitive and high-performing mobile solutions that meet and exceed client expectations. Abhishek's proficiency extends across various platforms, ensuring that OTUSONE LLP delivers cutting-edge applications tailored to clients' specific needs. His commitment to quality, coupled with a passion for innovation, solidifies his position as a key contributor to OTUSONE LLP's success and reputation in the industry."
        }
    ]
    useEffect(() => {
        const interval = setInterval(() => {
            handleImageClick(imgSrcList[currentIndex]);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imgSrcList.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex]);
    return (
        <Grid container className={styles.mobileAbout}>
            <Grid item sm={7} className={styles.newWorkAboutImg}>
                <img src={img1} width={91} height={100} className={selectedImg === "Akanksha Singh" ? styles.animate1 : ""} />
                <img src={img2} width={91} height={100} className={selectedImg === "Aparna Singh" ? styles.animate5 : ""} />
                <img src={img6} width={91} height={100} className={selectedImg === "Ankit Singh" ? styles.animate3 : ""} />
                <img src={img3} width={91} height={100} className={selectedImg === "Aman Shukla" ? styles.animate2 : ""} />
                <img src={img4} width={91} height={100} className={selectedImg === "Narendra Singh" ? styles.animate4 : ""} />
                <img src={img5} width={91} height={100} className={selectedImg === "Abhishek Yadav" ? styles.animate4 : ""} />
                <Grid className={styles.bgStyle}>
                </Grid>
            </Grid>
            <Grid item sm={5} className={styles.newWorkAboutInfo}>
                {data
                    .filter((item) => item.name === selectedName)
                    .map((item) => {
                        return (
                            <>
                                <Typography variant='h4' fontSize={25} fontWeight={600}>
                                    {item.name},
                                </Typography>
                                <Typography>{item.designation},</Typography>
                                <Typography>{item.url}</Typography>
                                <Typography variant='h5' fontSize={14} fontWeight={400}>
                                    {item.desc}
                                </Typography>
                                <Grid className={styles.bgStyle}>
                                    <FaQuoteLeft />
                                </Grid>
                            </>
                        )
                    })}
            </Grid>
        </Grid>
    )
}

export default ResponsiveAbout